import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
import AreaChart from "./AreaChart.vue";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Finance",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  components: {
    AreaChart
  },
  data() {
    return {
      loading: false,
      amountToday: "0",
      graphLabels: [],
      graphData: [],
      tableData: []
    };
  },
  computed: {
    financeGraph() {
      return store.getters["admin/reporting/financeGraph"];
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch("admin/reporting/getFinanceGraph").then(res => {
      let todayDate = new Date().toDateString();
      res.forEach(element => {
        this.graphData.push(element.daily_amount);
        this.graphLabels.push(element.day);
        if (new Date(element.day).toDateString() == todayDate) {
          this.amountToday = element.daily_amount;
        }
      });
    });
    store.dispatch("admin/reporting/getBundleHistory", {
      to: "",
      from: ""
    }).then(res => {
      this.tableData = res.splice(0, 10);
    }).finally(() => this.loading = false);
  }
};